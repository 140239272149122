import React, { useEffect, useRef, useState } from 'react';
import ChatMessage from './ChatMessage';
import css from './MessageList.module.scss';
import { assertNotNull, assertUuid4 } from '../utils/assert';
import { handleResponseErrors, loadChat } from '../utils/apiClient';
import mercure from '../utils/mercure';

export default function MessageList({
    eventId,
    handleReadCount,
    handleLastMessage,
}) {
    const [lastMercureEvent, setLastMercureEvent] = useState(null);
    const [messages, setMessages] = useState([]);

    const bottomRef = useRef(null);
    useEffect(() => {
        try {
            assertNotNull(eventId);
            assertUuid4(eventId);
        } catch (e) {
            return;
        }

        loadChat(eventId)
            .then((response) => {
                setMessages(response);
            })
            .catch((response) => {
                handleResponseErrors(response);
            });
    }, [lastMercureEvent]);

    let mercureEvent = null;

    useEffect(() => {
        mercureEvent = mercure(
            ['/api/events/' + eventId + '/chat'],
            lastMercureEvent?.lastEventId ?? null,
        );
        mercureEvent.onmessage = function (event) {
            console.log('mercure event', event);
            try {
                assertNotNull(event?.data);
                assertNotNull(event?.lastEventId);
            } catch (e) {
                handleResponseErrors(e);
                return;
            }

            let newEvent = JSON.parse(event.data);
            setLastMercureEvent(event.lastEventId);
            handleLastMessage(newEvent);
            handleReadCount();
        };

        mercureEvent.onerror = (e) => {
            handleResponseErrors(e);
        };

        return () => {
            try {
                mercureEvent?.close();
            } catch (e) {
                // intentional empty
            }
        };
    }, [eventId]);

    const scrollToBottom = (behavior = 'auto') => {
        bottomRef.current?.scrollIntoView({ behavior: behavior });
    };

    useEffect(() => {
        scrollToBottom('smooth');
    });

    /** will run once and skipp the scroll animation **/
    useEffect(() => {
        scrollToBottom('auto');
    }, [messages]);

    return (
        <div className={css.scrollable}>
            {messages?.length > 0 &&
                messages.map((message, index) => (
                    <ChatMessage key={index} message={message} />
                ))}
            <div ref={bottomRef} />
        </div>
    );
}
