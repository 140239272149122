import React from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { redirectToBackend } from './redirects';
import WhiteButton from '../Form/WhiteButton';
import i18n from './i18next';

const apiClient = axios.create({
    baseURL: '/',
    headers: {
        'Content-Type': 'application/json',
    },
});

let refreshingPromise = null;

apiClient.interceptors.request.use(
    async (config) => {
        if (!refreshingPromise) {
            await refreshingPromise;
        }

        return config;
    },
    (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                refreshingPromise = refreshCookie();

                await refreshingPromise;
                refreshingPromise = null;
                return axios(originalRequest); //recall Api with new token
            } catch (error) {
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    },
);

export function handleResponseErrors(error, callback) {
    if (error?.response?.status === 401) {
        enqueueSnackbar(i18n.t('error.authorization_failed'), {
            variant: 'error',
            preventDuplicate: true,
            key: 'network_error',
            action: () => (
                <WhiteButton
                    text="error.authorization_failed_btn"
                    action={redirectToBackend}
                />
            ),
        });
        return;
    }

    if (error?.response?.status === 422) {
        enqueueSnackbar(i18n.t(error.response?.data?.violations[0]?.message), {
            variant: 'warning',
            preventDuplicate: true,
            key: 'question_validation_error',
            action: () => (
                <WhiteButton
                    text="error.question_validation_error_btn"
                    action={() => window.location.reload()}
                />
            ),
        });
        return;
    }

    if (error?.response?.status === 500) {
        enqueueSnackbar(i18n.t('error.internal_server_error'), {
            variant: 'error',
            preventDuplicate: true,
            key: 'question_internal_server_error',
        });
        return;
    }

    if (typeof callback !== 'function') {
        return;
    }

    callback();
}

export const loginUser = (credentials) => {
    return apiClient
        .post('/api_login_check', credentials)
        .then((response) => response.data ?? null);
};

export const loadUserData = () => {
    return apiClient.get('/api/me/');
};

export const getEventById = (id) => {
    return apiClient.get(`/api/events/${id}`, {});
};

export const refreshCookie = () => {
    return axios.post(`/refresh_token`, {}, { maxRedirects: 0 });
};

export const loadQuestionCollection = (eventId) => {
    return apiClient
        .get(`/api/questions?event.id=${eventId}&order[sort]&order[createdAt]`)
        .then((response) => response.data ?? null);
};

export const loadQuestion = (id) => {
    return apiClient
        .get(`/api/questions/${id}`)
        .then((response) => response.data ?? null);
};

export const patchQuestion = (question) => {
    const id = question.id;
    delete question.id;

    return apiClient
        .patch(`/api/questions/${id}`, question, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        })
        .then((response) => response.data ?? null);
};

export const deleteQuestion = (id) => {
    return apiClient.delete(`/api/questions/${id}`);
};

export const newQuestion = (question) => {
    return apiClient.post(`/api/questions`, question);
};

export const postComment = (comment) => {
    return apiClient.post(`/api/comments`, comment);
};

export const deleteComment = (id) => {
    return apiClient.delete(`/api/comments/${id}`);
};

export const loadChat = (eventId) => {
    return apiClient
        .get(`/api/chat/messages?event.id=${eventId}`)
        .then((response) => response.data ?? null);
};

export const postChat = (eventId, message) => {
    return apiClient.post(`/api/chat/messages?event.id=${eventId}`, message);
};

export const deleteChatMessage = (eventId, message) => {
    return apiClient.delete(`/api/chat/messages/${eventId}`, message);
};

export const loadTimer = (eventId) => {
    return apiClient.get(`/api/timers?event.id=${eventId}`);
};

export const createTimer = (eventId, interval, start) => {
    return apiClient.post(`/api/timers`, {
        event: `/api/events/${eventId}`,
        start: start,
        duration: interval,
        active: true,
    });
};

export const deleteTimer = (id) => {
    return apiClient.delete(`/api/timers/${id}`);
};

export const patchTimer = (timer) => {
    let id = timer.id;
    delete timer.id;

    if (timer.start) {
        timer.start = timer.start.format('YYYY-MM-DDTHH:mm:ss');
    }

    if (timer.event) {
        timer.event = '/api/events/' + timer.event.id;
    }

    return apiClient.patch(`/api/timers/${id}`, timer, {
        headers: {
            'Content-Type': 'application/merge-patch+json',
        },
    });
};
