import React from 'react';
import css from './ChatMessage.module.scss';
import { useAtomValue } from 'jotai';
import { userAtom } from '../Atom/UserAtom';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { isAdmin } from '../utils/decider';
import { deleteChatMessage, handleResponseErrors } from '../utils/apiClient';
import { assertNotEmpty, assertTrue } from '../utils/assert';

export default function ChatMessage({ message: { id, body, date, account } }) {
    const userData = useAtomValue(userAtom);
    let className = css.wrapper;
    let isOwn = false;

    try {
        assertNotEmpty(account?.email);
        assertNotEmpty(userData?.email);
        assertTrue(userData?.email === account?.email);

        isOwn = true;
        className = css.message_own;
    } catch (e) {
        // intentionally empty
    }

    const handleMessageDelete = () => {
        deleteChatMessage(id).catch((error) => handleResponseErrors(error));
    };

    return (
        <div className={className}>
            <div className={css.message}>
                {(isOwn || isAdmin(userData)) && (
                    <div className={css.actions}>
                        <IconButton onClick={handleMessageDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
                <div className={css.messageContent}>{body}</div>
            </div>
            <div className={css.meta}>
                {isOwn || account?.email + ' - '}
                {date}
            </div>
        </div>
    );
}
